import linkField from './fields/link'

export default {
  title: 'Shop Menu',
  name: 'shopMenu',
  type: 'document',
  fields: [
    {
      name: 'shopMenuSections',
      title: 'Sections',
      type: 'array',
      of: [
        {
          name: 'shopMenuSection',
          title: 'Section',
          type: 'object',
          preview: {
            select: {
              items: 'shopMenuSectionItems'
            },
            prepare: ({ items }) => ({
              title: items
                .map(o => o.shopMenuItemLink?.title.trim())
                .filter(Boolean)
                .join(', '),
              subtitle: `${items?.length || 0} ${
                items.length === 1 ? 'item' : 'items'
              }`
            })
          },
          fields: [
            {
              name: 'shopMenuSectionItems',
              title: 'Item',
              type: 'array',
              of: [
                {
                  name: 'shopMenuItem',
                  title: 'Shop Menu Section Item',
                  type: 'object',
                  preview: {
                    select: {
                      title: 'shopMenuItemLink.title'
                    }
                  },
                  fields: [
                    {
                      title: 'Contains CBD',
                      name: 'cbd',
                      type: 'boolean',
                      initialValue: false,
                      description:
                        'If enabled, this record will be excluded from "scrubbed" or CBD-free storefronts.'
                    },
                    {
                      ...linkField,
                      name: 'shopMenuItemLink'
                    },
                    {
                      name: 'shopMenuItemIcon',
                      title: 'Icon',
                      description: 'optional',
                      type: 'image'
                    },
                    {
                      name: 'shopMenuItemSubtext',
                      title: 'Subtext',
                      description: 'optional',
                      type: 'string'
                    },
                    {
                      name: 'shopMenuItemSubmenu',
                      title: 'Submenu',
                      type: 'object',
                      description:
                        'submenus will only be visible if there are product categories below',
                      fields: [
                        {
                          name: 'shopSubmenuProductCategories',
                          title: 'Product Categories',
                          type: 'array',
                          of: [
                            {
                              name: 'shopSubmenuProductCategory',
                              title: 'Product Category',
                              type: 'reference',
                              to: [{ type: 'productCategory' }],
                              validation: Rule => Rule.required()
                            }
                          ]
                        },
                        {
                          name: 'shopSubmenuBackgroundColor',
                          title: 'Background Color',
                          type: 'color',
                          description:
                            'If a product category is selected above, the product category colors will override this option.'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              name: 'mobileOnly',
              title: 'Mobile Only',
              type: 'boolean'
            }
          ]
        }
      ]
    }
  ]
}
