<template>
  <li class="flex line-item">
    <div
      v-if="variant.image"
      class="flex-grow-0 flex-shrink-0 w-20 sm:w-24 xl:w-28"
    >
      <float
        :tag="'div'"
        class="relative block w-full h-24 cursor-pointer link link--never-fade"
        @click.prevent="handleItemClick"
      >
        <recess-image
          :src="variant.image.src"
          :max-width="200"
          :alt="title"
          contain
          fluid
        />
      </float>
    </div>
    <div class="flex-auto">
      <h3
        class="text-sm leading-tight cursor-pointer xs:text-base sm:text-lg mb-1/2em"
        @click.prevent="handleItemClick"
      >
        {{ product.title }}
      </h3>
      <div class="text-sm">
        <h5 class="mb-1/2em">${{ variant.price }}</h5>
        <div class="flex flex-no-wrap items-center mb-2em">
          <number-input
            v-model="quantity"
            :min="1"
            :max="maxQuantity"
            label="Qty"
            class="flex-auto text-sm font-medium"
          />
        </div>
      </div>
      <div v-if="showOptions">
        <div
          v-for="(option, index) in product.options"
          :key="option.id"
          class="line-item--option__wrapper"
        >
          <span class="line-item--option__name">{{ option.name }}</span>
          <div
            v-for="selection in option.values"
            :key="selection"
            :class="isSelected(`option${index + 1}`, selection)"
            class="line-item--option__selection"
            @click.prevent="selectVariant(`option${index + 1}`, selection)"
          >
            <span>{{ selection }}</span>
          </div>
        </div>
      </div>
    </div>
    <cta
      v-if="hasSelectableOptions && !showOptions"
      small
      fill
      :background-color="getColor('default-background')"
      :shadow-color="getColor('default-text-color')"
      :text-color="getColor('default-text-color')"
      :border-color="getColor('default-text-color')"
      class="relative flex self-center flex-shrink-0 text-lg normal-case z-above xl:text-xl"
      @click.prevent="openOptionSelector"
    >
      SELECT
    </cta>
    <cta
      v-else
      small
      fill
      :background-color="getColor('default-background')"
      :shadow-color="getColor('default-text-color')"
      :text-color="getColor('default-text-color')"
      :border-color="getColor('default-text-color')"
      class="relative flex self-center flex-shrink-0 text-lg normal-case z-above xl:text-xl"
      @click.prevent="addItemToCart"
    >
      ADD
    </cta>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NumberInput from '~/components/form/number-input.vue'
import formatLineItemSchema from '~/util/shopify/format-line-item-schema'

export default {
  name: 'RebuyLineItem',

  components: {
    NumberInput
  },

  props: {
    product: {
      default: () => {},
      type: Object
    }
  },

  data() {
    return {
      quantity: 1,
      selectedVariant: this.product.defaultVariant,
      showOptions: false
    }
  },

  computed: {
    ...mapGetters('shop', {
      productByShopifyId: 'productByShopifyId'
    }),
    variant() {
      return this.selectedVariant
    },
    title() {
      return this.product.title
    },
    maxQuantity() {
      const variant = this.variant
      if (!variant) {
        return undefined
      }
      if (variant.inventory_policy === 'continue') {
        return 9999
      }
      return variant.inventory_quantity
    },
    hasSelectableOptions() {
      for (const option of this.product.options) {
        if (option.values.length > 1) {
          return true
        }
      }
      return false
    }
  },

  watch: {
    quantity: {
      handler(newValue) {
        if (newValue === undefined || newValue === null) {
          this.quantity = 1
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('shop', {
      addToCart: 'addToCart'
    }),
    openOptionSelector() {
      this.showOptions = true
    },
    isSelected(optionKey, option) {
      if (this.selectedVariant[optionKey] === option) {
        return 'active'
      }
      return ''
    },
    handleItemClick() {
      this.$store.dispatch('shop/trackRebuySuggestedProduct', this.product.id)
      const shopifyProduct = this.productByShopifyId(this.product.id)
      const productPath = this.documentPath(shopifyProduct)
      this.$router.push(productPath)
    },
    async addItemToCart() {
      const attributes = [
        { key: '_attribution', value: 'Rebuy Cart' },
        { key: '_source', value: 'Rebuy' },
        { key: '_widget_id', value: 'recess_custom_cart_widget' }
      ]
      const lineItem = formatLineItemSchema(
        {
          id: this.product.id,
          productType: this.product.productType || 'Merch',
          title: this.product.title,
          hiddenPromotion: true,
          quantity: this.quantity,
          attributes,
          variant: {
            ...this.selectedVariant,
            price: {
              amount: this.selectedVariant.price
            },
            compareAtPrice: {
              amount: this.selectedVariant.compareAtPrice
            }
          }
        },
        'product'
      )
      await this.addToCart({ edge: lineItem, additive: true })
    },
    selectVariant(optionKey, option) {
      this.selectedVariant = this.product.variants.find(
        i => i[optionKey] === option
      )
    }
  }
}
</script>

<style scoped>
.line-item {
  flex-wrap: wrap;
  gap: min(2vw, 1rem);
  padding: 20px 0;
}

@screen 2xs {
  .line-item {
    flex-wrap: nowrap;
  }
}

.line-item:first-child {
  margin-top: 0;
}

.line-item /deep/ .number {
  --number-border: 0;
  --number-border-radius: 0;
  --number-height: 1em;
  --number-background: transparent;
  --number-gutter: 0 0.75em;
  align-items: center;
  display: flex;
  flex: nowrap;
}

.line-item /deep/ .number__label {
  margin-right: 0.75em;
  text-transform: uppercase;
}

.line-item--option__wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.line-item--option__name {
  text-transform: uppercase;
}

.line-item--option__selection {
  align-items: center;
  border: 2px solid #25375b;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  height: 30px;
  justify-content: center;
  margin-left: 10px;
  padding: 2px;
  width: 30px;
}

.line-item--option__selection.active {
  background-color: #b8cafc;
}
</style>
