export default {
  title: 'Link',
  name: 'link',
  type: 'object',
  fieldsets: [
    {
      name: 'options',
      title: 'Options'
    },
    {
      name: 'destination',
      title: 'Destination (choose one)'
    }
  ],
  fields: [
    {
      title: 'Title',
      name: 'title',
      description: 'The text displayed for the link or button.',
      type: 'string'
    },
    {
      title: 'Page',
      name: 'page',
      description: 'Choose a Recess page as the destination.',
      fieldset: 'destination',
      type: 'reference',
      to: [{ type: 'page' }]
    },
    {
      title: 'URL',
      name: 'url',
      description: 'Enter a relative path or full URL as the destination.',
      fieldset: 'destination',
      type: 'url',
      validation: Rule => Rule.uri({ allowRelative: true })
    },
    {
      title: 'Open this link in a new window',
      name: 'external',
      fieldset: 'options',
      type: 'boolean',
      initialValue: false
    },
    {
      title: 'Contains CBD',
      name: 'cbd',
      fieldset: 'options',
      type: 'boolean',
      initialValue: false,
      description:
        'If enabled, this record will be excluded from "scrubbed" or CBD-free storefronts.'
    }
  ],
  validation: Rule =>
    Rule.custom((value = {}) => {
      let valid = true
      const page = value.page
      if (!page) {
        return valid
      }
      const url = value.url
      const externalLink = value.external
      const pageAndUrl = page !== undefined && url !== undefined
      const pageAndExternal = page !== undefined && externalLink
      if (pageAndUrl) {
        valid = "Unset URL (page's URL will be determined automatically)"
      } else if (pageAndExternal) {
        valid = 'Page does not exist outside of the site'
      }
      return valid
    }),
  preview: {
    select: {
      title: 'title',
      pageTitle: 'page.title'
    },
    prepare: ({ title, pageTitle }) => ({
      title: title || pageTitle || 'New Page'
    })
  }
}
