import { IoIosLeaf } from 'react-icons/io'

export default {
  title: 'Ingredient',
  name: 'ingredient',
  type: 'document',
  icon: IoIosLeaf,
  fields: [
    {
      title: 'Contains CBD',
      name: 'cbd',
      type: 'boolean',
      initialValue: false,
      description:
        'If enabled, this record will be excluded from "scrubbed" or CBD-free storefronts.'
    },
    {
      title: 'Title',
      name: 'title',
      type: 'string',
      validation: Rule => Rule.required().error('A title is required.')
    },
    {
      title: 'Image',
      name: 'image',
      type: 'image',
      description: 'Transparent, closely cropped image',
      validation: Rule => Rule.required().error('An image is required.')
    },
    {
      title: 'Short Description',
      name: 'description',
      type: 'text',
      rows: 2
    },
    {
      title: 'Long Description',
      name: 'longDescription',
      type: 'text',
      rows: 6
    }
  ],
  preview: {
    select: {
      title: 'title',
      media: 'image'
    }
  }
}
