// import React from 'react'
import { AiTwotoneExperiment } from 'react-icons/ai'
import basicEditor from './blocks/basic-editor'

export default {
  title: 'Lab Results',
  name: 'labResults',
  type: 'document',
  icon: AiTwotoneExperiment,
  __experimental_actions: ['update', 'publish'],
  fields: [
    {
      title: 'Contains CBD',
      name: 'cbd',
      type: 'boolean',
      initialValue: false,
      description:
        'If enabled, this record will be excluded from "scrubbed" or CBD-free storefronts.'
    },
    {
      title: 'Title',
      name: 'pageTitle',
      type: 'string',
      validation: Rule => Rule.required()
    },
    {
      ...basicEditor,
      title: 'Subtitle',
      name: 'subTitle',
      validation: Rule => Rule.required()
    },
    {
      title: 'Hemp-free test results',
      name: 'hempFreeTestResults',
      type: 'file'
    },
    {
      title: 'Lab Results',
      name: 'labResults',
      type: 'array',
      of: [
        {
          title: 'Flavor',
          name: 'flavor',
          type: 'object',
          fields: [
            {
              title: 'Name',
              name: 'name',
              type: 'string'
            },
            {
              title: 'Product',
              name: 'product',
              type: 'reference',
              to: [{ type: 'product' }]
            },
            {
              title: 'Lot Code',
              name: 'lotCode',
              type: 'string'
            },
            {
              title: 'Certificate of Analysis',
              name: 'coa',
              type: 'file'
            }
          ],
          preview: {
            select: {
              title: 'name',
              subtitle: 'lotCode',
              image: 'product.thumb.image',
              shopifyImage: 'product.shopify.image'
            },
            prepare: ({ title, subtitle, image, shopifyImage }) => ({
              title,
              subtitle,
              media: image || shopifyImage
            })
          }
        }
      ]
    }
  ]
}
