import {
  AiFillShopping,
  AiFillInfoCircle,
  AiFillPushpin,
  AiFillAmazonCircle,
  AiOutlinePercentage,
  AiTwotoneExperiment
} from 'react-icons/ai'
import { GiSodaCan, GiBarefoot } from 'react-icons/gi'
import { TiHome, TiHomeOutline, TiSocialAtCircular } from 'react-icons/ti'
import { BsChevronDown } from 'react-icons/bs'
import {
  MdPercent,
  MdSettings,
  MdViewList,
  MdShoppingCart
} from 'react-icons/md'
import { FaWarehouse } from 'react-icons/fa'
import { RiMenuUnfoldLine } from 'react-icons/ri'
import {
  IoMdHome,
  IoIosPricetags,
  IoMdColorWand,
  IoIosLeaf,
  IoIosMail,
  IoIosChatboxes,
  IoIosInformationCircle,
  IoIosAddCircle
} from 'react-icons/io'
import { SiMinutemailer } from 'react-icons/si'

const hiddenTypes = [
  'link',
  'home',
  'homeNonCBD',
  'product',
  'productCategory',
  'featuredProduct',
  'promotionalProduct',
  'buyNow',
  'ingredients',
  'ingredient',
  'whatsInside',
  'whereToBuy',
  'wholesale',
  'labResults',
  'menus',
  'cart',
  'socialMedia',
  'siteMeta',
  'klaviyo',
  'signUp',
  'siteFooter',
  'testimonialsPage',
  'globalBanner',
  'discountBanner',
  'amazonSettings',
  'productCategoryCollection',
  'scrollIndicator',
  'shopMenu',
  'form'
]

/**
 * @type {import('sanity/desk').StructureResolver}
 */
const structure = S =>
  S.list()
    .title('Content')
    .items([
      S.listItem()
        .title('Site Settings')
        .child(
          S.list()
            .title('Site Settings')
            .items([
              S.listItem()
                .title('Shop Menu')
                .child(
                  S.editor()
                    .title('Shop Menu')
                    .id('shop-menu')
                    .schemaType('shopMenu')
                    .documentId('shop-menu')
                )
                .icon(RiMenuUnfoldLine),
              S.listItem()
                .title('Scroll Indicator')
                .child(
                  S.editor()
                    .title('Scroll Indicator')
                    .id('scroll-indicator')
                    .schemaType('scrollIndicator')
                    .documentId('scroll-indicator')
                )
                .icon(BsChevronDown),
              S.listItem()
                .title('Amazon Settings')
                .child(
                  S.editor()
                    .title('Amazon Settings')
                    .id('amazon-settings')
                    .schemaType('amazonSettings')
                    .documentId('amazon-settings')
                )
                .icon(AiFillAmazonCircle),
              S.listItem()
                .title('Site Menus')
                .child(
                  S.editor()
                    .title('Site Menus')
                    .id('site-menus')
                    .schemaType('menus')
                    .documentId('site-menus')
                )
                .icon(MdViewList),
              S.listItem()
                .title('Cart')
                .child(
                  S.editor()
                    .title('Cart')
                    .id('cart')
                    .schemaType('cart')
                    .documentId('cart')
                )
                .icon(MdShoppingCart),
              S.listItem()
                .title('Global Banner')
                .child(
                  S.editor()
                    .title('Global Banner')
                    .id('global-ganner')
                    .schemaType('globalBanner')
                    .documentId('global-banner')
                )
                .icon(AiFillPushpin),
              S.listItem()
                .title('Discount Banner')
                .child(
                  S.editor()
                    .title('Discount Banner')
                    .id('discount-banner')
                    .schemaType('discountBanner')
                    .documentId('discount-banner')
                )
                .icon(AiFillPushpin),
              S.listItem()
                .title('Discount Code Settings')
                .child(
                  S.editor()
                    .title('Discount Code Settings')
                    .id('discount-code-settings')
                    .schemaType('discountCodeSettings')
                    .documentId('discount-code-settings')
                )
                .icon(AiOutlinePercentage),
              S.listItem()
                .title('Social Media')
                .child(
                  S.editor()
                    .title('Social Media')
                    .id('social-media')
                    .schemaType('socialMedia')
                    .documentId('social-media')
                )
                .icon(TiSocialAtCircular),
              S.listItem()
                .title('Klaviyo')
                .child(
                  S.editor()
                    .title('Klaviyo Settings')
                    .id('klaviyo-settings')
                    .schemaType('klaviyo')
                    .documentId('klaviyo')
                )
                .icon(IoIosMail),
              S.listItem()
                .title('Site Meta')
                .child(
                  S.editor()
                    .title('Site Meta')
                    .id('site-meta')
                    .schemaType('siteMeta')
                    .documentId('site-meta')
                )
                .icon(IoIosInformationCircle),
              S.listItem()
                .title('Rebuy')
                .child(
                  S.editor()
                    .title('Rebuy Settings')
                    .id('rebuy-settings')
                    .schemaType('rebuy')
                    .documentId('rebuy')
                )
                .icon(IoIosAddCircle),
              S.listItem()
                .title('Site Footer')
                .child(
                  S.editor()
                    .title('Site Footer')
                    .id('site-footer')
                    .schemaType('siteFooter')
                    .documentId('site-footer')
                )
                .icon(GiBarefoot)
            ])
        )
        .icon(MdSettings),
      S.listItem()
        .title('Home')
        .child(
          S.list()
            .title('Home')
            .items([
              S.listItem()
                .title('Home')
                .child(
                  S.editor()
                    .title('Home')
                    .id('home')
                    .schemaType('home')
                    .documentId('home')
                )
                .icon(TiHome),
              S.listItem()
                .title('Non-CBD Home')
                .child(
                  S.editor()
                    .title('Non-CBD Home')
                    .id('home-non-cbd')
                    .schemaType('homeNonCBD')
                    .documentId('home-non-cbd')
                )
                .icon(TiHomeOutline)
            ])
        )
        .icon(IoMdHome),
      S.listItem()
        .title('Shop')
        .child(
          S.list()
            .title('Shop')
            .items([
              S.listItem()
                .title('Products')
                .child(S.documentTypeList('product').title('Products'))
                .icon(GiSodaCan),
              S.listItem()
                .title('Product Categories')
                .icon(IoIosPricetags)
                .child(
                  S.documentList()
                    .title('Parent Categories')
                    .schemaType('productCategory')
                    .filter('_type == "productCategory"')
                ),
              S.listItem()
                .title('Category Collections')
                .child(
                  S.documentList()
                    .title('Category Collection')
                    .schemaType('productCategoryCollection')
                    .filter('_type == "productCategoryCollection"')
                ),
              S.listItem()
                .title('Featured Product')
                .child(
                  S.editor()
                    .title('Featured Product')
                    .id('featured-product')
                    .schemaType('featuredProduct')
                    .documentId('featured-product')
                )
                .icon(IoMdColorWand),
              S.listItem()
                .title('Promotional Products')
                .child(
                  S.documentTypeList('promotionalProduct').title(
                    'Promotional Products'
                  )
                )
                .icon(MdPercent)
            ])
        )
        .icon(AiFillShopping),
      S.listItem()
        .title('Buy Now Pages')
        .child(S.documentTypeList('buyNow').title('Buy Now Pages'))
        .icon(GiSodaCan),
      S.listItem()
        .title('Ingredients')
        .child(
          S.list()
            .title('Ingredients')
            .items([
              S.listItem()
                .title('Ingredients Settings')
                .child(
                  S.editor()
                    .title('Ingredients Settings')
                    .id('ingredients-settings')
                    .schemaType('ingredients')
                    .documentId('ingredients-settings')
                )
                .icon(MdSettings),
              S.listItem()
                .title('Ingredients')
                .icon(IoIosLeaf)
                .child(S.documentTypeList('ingredient').title('Ingredients'))
            ])
        )
        .icon(IoIosLeaf),
      S.listItem()
        .title("What's Inside")
        .child(
          S.list()
            .title("What's Inside")
            .items([
              S.listItem()
                .title("What's Inside Page")
                .child(
                  S.editor()
                    .title("What's Inside Page")
                    .id('whats-inside-page')
                    .schemaType('whatsInside')
                    .documentId('whats-inside-page')
                )
                .icon(AiFillInfoCircle)
            ])
        )
        .icon(AiFillInfoCircle),
      S.listItem()
        .title('Where to Buy')
        .child(
          S.list()
            .title('Where to Buy')
            .items([
              S.listItem()
                .title('Where to Buy Page')
                .child(
                  S.editor()
                    .title('Where to Buy Page')
                    .id('where-to-buy-page')
                    .schemaType('whereToBuy')
                    .documentId('where-to-buy-page')
                )
                .icon(AiFillInfoCircle)
            ])
        )
        .icon(AiFillInfoCircle),
      S.listItem()
        .title('Wholesale')
        .child(
          S.list()
            .title('Wholesale')
            .items([
              S.listItem()
                .title('Wholesale Page')
                .child(
                  S.editor()
                    .title('Wholesale Page')
                    .id('wholesale-page')
                    .schemaType('wholesale')
                    .documentId('wholesale-page')
                )
                .icon(FaWarehouse)
            ])
        )
        .icon(FaWarehouse),
      S.listItem()
        .title('Lab Results')
        .child(
          S.list()
            .title('Lab Results')
            .items([
              S.listItem()
                .title('Lab Results Page')
                .child(
                  S.editor()
                    .title('Lab Results Page')
                    .id('labresults-page')
                    .schemaType('labResults')
                    .documentId('labresults-page')
                )
                .icon(AiTwotoneExperiment)
            ])
        )
        .icon(AiTwotoneExperiment),
      S.listItem()
        .title('Testimonials Page')
        .child(
          S.list()
            .title('Testimonials')
            .items([
              S.listItem()
                .title('Testimonials Page')
                .child(
                  S.editor()
                    .title('Testimonials Page')
                    .id('testimonials-page')
                    .schemaType('testimonialsPage')
                    .documentId('testimonials-page')
                )
                .icon(IoIosChatboxes)
            ])
        )
        .icon(IoIosChatboxes),
      S.listItem()
        .title('Sign Up Pages')
        .child(S.documentTypeList('signUp').title('Sign Up Pages'))
        .icon(SiMinutemailer),
      S.listItem()
        .title('Contact Forms')
        .child(S.documentTypeList('form').title('Contact Forms'))
        .icon(IoIosMail),
      ...S.documentTypeListItems().filter(
        listItem => !hiddenTypes.includes(listItem.getId())
      )
    ])

export default structure
