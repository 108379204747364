import merge from '~lodash.mergewith'
import { init, Integrations, Replay } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002Fa0f0bf300d0a47cd95cf604df425fe08@o913796.ingest.sentry.io\u002F5854609",
    environment:"production",
    beforeSend:function(event) {
        const exception = event.exception?.values?.[0];

        // Ignore "Uncaught ReferenceError: e is not defined"
        const isReferenceErrorE =
        exception?.type === 'Error' &&
        exception?.value === 'Uncaught ReferenceError: e is not defined';

        // Ignore "Object captured as promise rejection with keys: details, message, name"
        const isGotoInterrupted =
        exception?.type === 'UnhandledRejection' &&
        exception?.value ===
        'Object captured as promise rejection with keys: details, message, name';

        // Ignore "Event `CustomEvent` (type=unhandledrejection) captured as promise rejection"
        const isCustomEventUnhandledRejection =
        exception?.type === 'CustomEvent' &&
        exception?.value ===
        'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection';

        // Drop the event if it matches any of the above conditions
        if (
        isReferenceErrorE ||
        isGotoInterrupted ||
        isCustomEventUnhandledRejection)
        {
          return null;
        }

        return event;
      },
    ignoreErrors:[new RegExp("AutofillCallbackHandler", ""),new RegExp("Can't find variable: offset", ""),new RegExp("offset is not defined", ""),new RegExp("resizeFr is not a function", ""),new RegExp("jju is not a function", ""),new RegExp("Expect-CT", ""),new RegExp("Non-Error promise rejection captured with keys", ""),new RegExp("Non-Error exception captured with keys", ""),new RegExp("isDestroyed", ""),new RegExp("The operation would yield an incorrect node tree", ""),new RegExp("NotAllowedError", ""),new RegExp("analytics\\.user is not a function", ""),new RegExp("Page not found", ""),new RegExp("bwp widget has not been created yet", ""),new RegExp("Load failed", ""),new RegExp("Failed to fetch", ""),new RegExp("PerformanceMonitoringJavascriptInterface is not defined", ""),new RegExp("ResizeObserver loop limit exceeded", ""),new RegExp("Non-Error promise rejection captured with value: Object Not Found Matching", ""),new RegExp("ProgressEvent", "")],
    replaysSessionSampleRate:0.1,
    replaysOnErrorSampleRate:1,
    release:"4bb8396617c993af0916e660e6210b6d182bc0b5",
  }

  const { Breadcrumbs } = Integrations

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
    new Breadcrumbs({ dom:false }),
    new Replay({ maskAllText:false,blockAllMedia:false,networkDetailAllowUrls:["https:\u002F\u002Ftakearecess.myshopify.com\u002Fapi\u002F"],networkCaptureBodies:true }),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
