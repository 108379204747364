import { TiHome } from 'react-icons/ti'
import basicEditor from './blocks/basic-editor'
import contentEditor from './blocks/content-editor'
import linkField from './fields/link'

export default {
  title: 'Home',
  name: 'home',
  type: 'document',
  icon: TiHome,
  __experimental_actions: ['update', 'publish'],
  groups: [
    {
      default: true,
      name: 'featured',
      title: '1. Featured'
    },
    {
      name: 'hero',
      title: '2. Hero'
    },
    {
      name: 'floatingCTA',
      title: '3. Floating CTA'
    },
    {
      name: 'intro',
      title: '4. Introduction'
    },
    {
      name: 'sampler',
      title: '5. Samplers'
    },
    {
      name: 'zeroproof',
      title: '6. Zero Proof'
    },
    {
      name: 'mood',
      title: '7. Mood'
    },
    {
      name: 'water',
      title: '8. Sparkling Water'
    },
    {
      name: 'press',
      title: '9. Press',
      options: {
        collapsible: true
      }
    },
    {
      name: 'testimonials',
      title: '10. Testimonials',
      options: {
        collapsible: true
      }
    },
    {
      name: 'signup',
      title: '11. Newsletter',
      options: {
        collapsible: true
      }
    }
  ],
  fields: [
    // featured section
    {
      title: 'Enable Featured Section',
      name: 'featuredProductEnabled',
      group: 'featured',
      type: 'boolean',
      description:
        'Determines whether the featured section should be displayed on the site or not. If enabled, it will be shown at the top of the page, above the Hero section.'
    },
    {
      title: 'Title',
      name: 'featuredProductTitle',
      description: 'The title of the featured section.',
      type: 'string',
      group: 'featured',
      options: {
        source: 'title',
        maxLength: 96
      },
      hidden: ({ document }) => document?.featuredProductEnabled === false
    },
    {
      ...basicEditor,
      title: 'Subtitle',
      description: 'The subtitle of the featured section.',
      name: 'featuredProductText',
      group: 'featured',
      hidden: ({ document }) => document?.featuredProductEnabled === false
    },
    {
      ...linkField,
      title: 'CTA',
      name: 'featuredProductCta',
      description:
        'The Call To Action button details, including the text and destination (internal page, URL, etc.). Styling customizations are available below.',
      group: 'featured',
      hidden: ({ document }) => document?.featuredProductEnabled === false
    },
    {
      title: 'Title and Subtitle Text Color',
      name: 'featuredProductCtaTextColor',
      description:
        'The color of the title and subtitle. Leave empty for default text color.',
      type: 'color',
      group: 'featured',
      hidden: ({ document }) => document?.featuredProductEnabled === false
    },
    {
      title: 'CTA Button Text Color',
      name: 'featuredProductCtaButtonTextColor',
      description:
        'The color of the text that will be displayed in the CTA button.',
      type: 'color',
      group: 'featured',
      hidden: ({ document }) => document?.featuredProductEnabled === false
    },
    {
      title: 'CTA Button Background Color',
      name: 'featuredProductCtaButtonBackgroundColor',
      description: 'The background color of the CTA button.',
      type: 'color',
      group: 'featured',
      hidden: ({ document }) => document?.featuredProductEnabled === false
    },
    {
      title: 'Header Top Gradient Color',
      name: 'featuredProductHeaderTopGradientColor',
      description:
        'If no color is defined here, the default value will be used (#A2B0FF). This gradient overwrite will only work on the home page.',
      type: 'color',
      group: 'featured',
      hidden: ({ document }) => document?.featuredProductEnabled === false
    },
    {
      title: 'Image',
      name: 'featuredProductImage',
      description:
        'The image that will be displayed on the right side of the featured section.',
      type: 'image',
      group: 'featured',
      hidden: ({ document }) => document?.featuredProductEnabled === false
    },
    {
      title: 'Section Background Image',
      name: 'featuredProductBackgroundImage',
      description: 'The background image of the featured section.',
      type: 'image',
      group: 'featured',
      hidden: ({ document }) => document?.featuredProductEnabled === false
    },
    // hero
    {
      title: 'Vertical Layout',
      name: 'splashIsVerticalLayout',
      type: 'boolean',
      group: 'hero',
      description:
        'Determines whether the layout should always be vertical or not. When vertical, the hero image will be displayed under the title and subtitle.'
    },
    {
      title: 'Title',
      name: 'splashTitle',
      description: 'Title for the Hero section.',
      type: 'string',
      group: 'hero'
    },
    {
      ...basicEditor,
      title: 'Subtitle',
      name: 'splashText',
      description: 'Subtitle for the Hero section.',
      group: 'hero'
    },
    {
      ...linkField,
      title: 'CTA',
      name: 'splashCta',
      description:
        'The Call To Action button details, including the text and destination (internal page, URL, etc.). Defaults to "Shop," which leads to Sparkling Water page.',
      group: 'hero'
    },
    {
      title: 'Image',
      name: 'splashHero',
      description: 'The image that will be displayed section.',
      type: 'image',
      group: 'hero'
    },
    // Floating CTA
    {
      ...linkField,
      title: 'Floating CTA',
      name: 'floatingCta',
      group: 'floatingCTA',
      description:
        'The Floating Call To Action button details, including the text and destination (internal page, URL, etc.). This button will be displayed on the bottom right of the page and stay there as the user scrolls down the page.'
    },
    // intro
    {
      title: 'Title',
      name: 'introTitle',
      description: 'The title of the Intro section.',
      type: 'string',
      group: 'intro'
    },
    {
      ...contentEditor,
      title: 'Text',
      name: 'introText',
      description: 'The content of the Intro section.',
      group: 'intro'
    },
    // sampler
    {
      title: 'Title',
      name: 'samplerTitle',
      description: 'The title of the Samplers section.',
      type: 'string',
      group: 'sampler'
    },
    {
      title: 'Subtitle',
      name: 'samplerSubtitle',
      description: 'The Subtitle of the Samplers section.',
      type: 'text',
      group: 'sampler'
    },
    {
      title: 'Featured Sampler',
      name: 'featuredSampler',
      description: 'The Sampler products to feature in this section.',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      validation: Rule => Rule.max(8),
      group: 'sampler'
    },
    // zero proof
    {
      title: 'Title',
      name: 'zeroproofTitle',
      description: 'The Title of the Zero Proof section.',
      type: 'string',
      group: 'zeroproof'
    },
    {
      title: 'Subtitle',
      name: 'zeroproofSubtitle',
      description: 'The Subtitle of the Zero Proof section.',
      type: 'text',
      group: 'zeroproof'
    },
    {
      title: 'Featured Zero Proof',
      name: 'featuredZeroproof',
      description: 'The Zero Proof products to feature in this section.',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      validation: Rule => Rule.max(8),
      group: 'zeroproof'
    },
    // mood section
    {
      title: 'Mood Product Category',
      name: 'moodProductCategory',
      description: 'Used to pull the background color gradients.',
      type: 'reference',
      group: 'mood',
      validation: Rule => Rule.required(),
      to: [{ type: 'productCategory' }]
    },
    {
      title: 'Mood Water Title',
      name: 'moodWaterTitle',
      description: 'The Title of the Mood Water section.',
      type: 'string',
      group: 'mood'
    },
    {
      title: 'Mood Water Subtitle',
      name: 'moodWaterSubtitle',
      description: 'The Subtitle of the Mood Water section.',
      type: 'text',
      group: 'mood'
    },
    {
      title: 'Featured Mood Water Products',
      name: 'moodWaterProducts',
      description:
        'The list of the Mood Water products to feature in this section.',

      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      validation: Rule => Rule.max(8),
      group: 'mood'
    },
    {
      title: 'Title',
      name: 'moodPowderTitle',
      description: 'The Title of the Mood Powder section.',
      type: 'string',
      group: 'mood'
    },
    {
      title: 'Subtitle',
      name: 'moodPowderSubtitle',
      description: 'The Subtitle of the Mood Powder section.',

      type: 'text',
      group: 'mood'
    },
    {
      title: 'Featured Mood Powder Products',
      name: 'moodPowderProducts',
      description:
        'The list of the Mood Powder products to feature in this section.',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      validation: Rule => Rule.max(8),
      group: 'mood'
    },
    // sparkling water
    {
      title: 'Title',
      name: 'waterTitle',
      description: 'The Title of the Sparkling Water section.',
      type: 'string',
      group: 'water'
    },
    {
      title: 'Subtitle',
      name: 'waterSubtitle',
      description: 'The Subtitle of the Sparkling Water section.',
      type: 'text',
      group: 'water'
    },
    {
      title: 'Featured Sparkling Water',
      name: 'featuredWater',
      description:
        'The list of the Sparkling Water products to feature in this section.',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ],
      validation: Rule => Rule.max(8),
      group: 'water'
    },
    // press
    {
      title: 'Title',
      name: 'pressTitle',
      description: 'The Title of the Press section.',
      type: 'string',
      group: 'press'
    },
    {
      title: 'Featured',
      name: 'featuredPress',
      description: 'The list of press quotes to feature in this section.',
      type: 'array',
      of: [
        {
          title: 'Press',
          name: 'press',
          type: 'reference',
          to: [{ type: 'press' }]
        }
      ],
      validation: Rule => Rule.max(4),
      group: 'press'
    },
    // testimonials
    {
      title: 'Testimonials',
      name: 'testimonials',
      description:
        'The list of customer testimonials to feature in this section',
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [{ type: 'testimonial' }]
        }
      ],
      validation: Rule => Rule.max(8),
      group: 'testimonials'
    },
    // signup
    {
      title: 'Title',
      name: 'signupTitle',
      description: 'The Title of the Newsletter Signup section.',
      type: 'string',
      group: 'signup'
    },
    {
      ...basicEditor,
      title: 'Content',
      description: 'The Subtitle of the Newsletter Signup section.',
      name: 'signupText',
      group: 'signup'
    }
  ]
}
